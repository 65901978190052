<template>
  <div class="positionT0L0">
    <p class="title">作品/制品信息</p>
    <div class="line">
      <div class="left">作品名称：</div>
      <div class="right">{{ form.productName }}</div>
    </div>
    <div class="line">
      <div class="left">作品展示图片：</div>
      <div class="img_box" v-if="form.productImageArr[0]">
        <img
          v-viewer
          v-for="(item, index) in form.productImageArr"
          :key="index"
          :src="item"
        />
      </div>
    </div>
    <div class="line">
      <div class="left">创建时间：</div>
      <div class="right">{{ form.createTime }}</div>
    </div>
    <div class="line">
      <div class="left">创作性质：</div>
      <div class="right">{{ form.productCreateNatName }}</div>
    </div>
    <div class="line">
      <div class="left">作品类别：</div>
      <div class="right">{{ form.productCatName }}</div>
    </div>
    <!-- <div class="line">
      <div class="left">状态：</div>
      <div class="right" v-if="form.status == 1">申请中</div>
      <div class="right" v-if="form.status == 2">待登记</div>
      <div class="right" v-if="form.status == 3">已登记</div>
    </div> -->
    <div class="line">
      <div class="left">证书生成状态：</div>
      <div class="right" v-if="form.blockBuildStatus == 0">未生成</div>
      <div class="right" v-if="form.blockBuildStatus == 1">已生成</div>
    </div>
    <div class="line mb40">
      <div class="left">作品描述：</div>
      <div class="right">{{ form.productDescribe }}</div>
    </div>

    <div class="title">权属信息</div>
    <div class="line mb40">
      <div class="left">存证者：</div>
      <div class="right">
        {{ form.submitterName }} ({{ form.submitterIdcard }})
      </div>
    </div>
    <div class="title">证书信息</div>
    <div class="line">
      <div class="left">证书：</div>
      <div class="img_box2" v-if="form.newBlockCertificateImgUrlArr[0]">
        <img
          v-viewer
          v-for="(item, index) in form.newBlockCertificateImgUrlArr"
          :key="index"
          :src="item"
        />
      </div>
    </div>
    <FooterToolBar :collapsed="sideCollapsed">
      <a-button class="margin_right60" @click="() => $router.go(-1)">
        返回
      </a-button>
    </FooterToolBar>
  </div>
</template>

<script>
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
import { CunZhengDetailsApi } from "@/request/api/certificate.js";
export default {
  mixins: [baseMixin],
  components: { FooterToolBar },
  created() {
    let id = this.$route.params.id;
    CunZhengDetailsApi({ id: id }).then(({ code, data }) => {
      if (code == 200) {
        this.form = data;
        this.form.productImageArr = data.productImage
          ? data.productImage.split(",")
          : [];
        this.form.newBlockCertificateImgUrlArr = data.newBlockCertificateImgUrl
          ? data.newBlockCertificateImgUrl.split(",")
          : [];
      }
    });
  },
  data() {
    return {
      form: {
        productImageArr: [],
        newBlockCertificateImgUrlArr: [],
      },
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.title {
  padding: 10px 16px;
  color: #00153c;
  background: #f4f8ff;
  font-weight: 600;
}
.line {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  color: #00153c;
  .left {
    width: 100px;
    text-align: right;
  }
  .img_box {
    img {
      margin-right: 20px;
      width: 90px;
      height: 90px;
    }
  }
  .img_box2 {
    img {
      width: 385px;
      height: 560px;
      margin-right: 20px;
    }
  }
}
.mb40 {
  margin-bottom: 40px;
}
</style>
