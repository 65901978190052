// 存证相关接口
import request from '@/request/request'

// 存证列表
export const CunZhengListApi = (params) => request.post(`/frame-web/admin/copyright/deposit/certificate/page`, params);
// 存证详情
export const CunZhengDetailsApi = (params) => request.get(`/frame-web/admin/copyright/deposit/certificate/details`, {params});


// 登记列表
export const DengjiListApi = (params) => request.post(`/frame-web/admin/copyright/registration/page`, params);
// 登记详情
export const DengjiDetailApi = (params) => request.get(`/frame-web/admin/copyright/registration/productRegisterInfo`, {params});
// 统计当日登记数量情况
export const DengjiResultApi = (params) => request.get(`/frame-web/admin/copyright/registration/getStatRegistrationResult`, {params});